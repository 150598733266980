
import CreateDiscountPopoverService from '@/services/createDiscountPopoverService';
import { DiscountType } from '@/enums/DiscountType';
import Discount from '@/Interfaces/Discount';
import CalculationResults from '@/Interfaces/CalculationResults';
import RoleService from '@/services/RoleService';
import LocalStorageService from '@/services/LocalStorageService';
import UserService from '@/services/UserService';
import { defineComponent } from 'vue';

interface ComponentData {
  activateNotification: boolean;
  typeNotification: 'success' | 'error';
  titleNotification: string;
  messageNotification: string;

  createDiscountService: CreateDiscountPopoverService;
  calcResults: CalculationResults;
  localStorageService: LocalStorageService;
  roleService: RoleService;
  userService: UserService;
  locs: any[];
  selectedLocationId: string;
  locationOptions: Array<{ value: string | number | null; text: string }>;
  selectedLocationDiscount: any;
  selectedDiscount: any;
  monetaryValue: string;
  percentageValue: string;
  discountName: string;
  discountOptions: Array<{ value: DiscountType; text: string }>;
  popoverShow: boolean;
  locationDiscount: any[];
  deleteDiscountId: string;
  deleteDiscountName: string;
  createDiscount: boolean;
  discountPin: string;
  userId: string;
  loading: boolean;
  locationId: string;
  isSuperadminLoc: boolean;
  showConfirmDelete: boolean;
  showUpdateModal: boolean;
  selectedDiscountForDelete: [];
}

export default defineComponent({
  name: 'ListDiscounts',
  data() {
    const data: ComponentData = {
      activateNotification: false,
      typeNotification: 'success',
      titleNotification: '',
      messageNotification: '',

      createDiscountService: undefined,
      calcResults: undefined,
      localStorageService: undefined,
      roleService: undefined,
      userService: undefined,
      locs: [],
      selectedLocationId: undefined,
      locationOptions: [{ value: null, text: 'Please select a location' }],
      selectedLocationDiscount: {},
      selectedDiscount: undefined,
      monetaryValue: '',
      percentageValue: '',
      discountName: '',
      discountOptions: [
        { value: DiscountType.Monetary, text: '$' },
        { value: DiscountType.Percentage, text: '%' },
      ],
      popoverShow: false,
      locationDiscount: [],
      deleteDiscountId: '',
      deleteDiscountName: '',
      createDiscount: false,
      discountPin: '',
      userId: '',
      loading: false,
      locationId: '',
      isSuperadminLoc: false,
      showConfirmDelete: false,
      showUpdateModal: false,
      selectedDiscountForDelete: [],
    };
    return data;
  },
  computed: {
    getDeleteMessage() {
      const locationOfUsers = JSON.parse(
        this.localStorageService.getItem('locations'),
      );
      const currentLocation = locationOfUsers.filter(
        (data) => data.id === this.selectedLocationId,
      );
      return `Do you want to delete it for all locations or just ${currentLocation[0].name} ?`;
    },
  },
  created() {
    this.createDiscountService = new CreateDiscountPopoverService();
    this.calcResults = new CalculationResults();
    this.localStorageService = new LocalStorageService();
    this.roleService = new RoleService(new LocalStorageService());
    this.userService = new UserService();
    this.selectedLocationId = this.localStorageService.getItem(
      'primary_location_id',
    );
    this.selectedDiscount = DiscountType.Percentage;
    this.discountOptions = [
      { value: DiscountType.Monetary, text: '$' },
      { value: DiscountType.Percentage, text: '%' },
    ];
  },
  async mounted() {
    this.loading = true;
    this.isSuperadminLoc = false;
        await this.$store.dispatch('getLocationList');
    // const locations = this.$store.getters.allLocationList;
    this.locs = this.$store.getters.allLocationList;
    this.locationOptions = this.locationOptions.concat(
      this.locs.map((location: any) => {
        return { value: location.id, text: location.name };
      }),
    );
    if (this.roleService.isStaffOrAdmin) {
      const locationOfUsers = this.localStorageService.getItem('locations');
      if (locationOfUsers != null) {
        const _locationOfUsers = JSON.parse(locationOfUsers);
        if (_locationOfUsers.length === 1) {
          this.selectedLocationId =
            _locationOfUsers[0].id ||
            this.localStorageService.getItem('primary_location_id');
        }
      }
    }
    if (this.selectedLocationId) {
      this.locationId = this.selectedLocationId;
    }
    const location = this.locs.filter((l: any) => l.id === this.locationId)[0];
    this.discountPin = location.discount_pin;
    const locationOfSuperadmin = this.localStorageService.getItem('locations');
    if (
      locationOfSuperadmin &&
      locationOfSuperadmin.slice(2, -2) !== this.$route.query.locationId &&
      this.roleService.isSuperadmin
    ) {
      this.isSuperadminLoc = true;
    }
    this.userId = this.localStorageService.getItem('userId');
    await this.getAllDiscountLocations();
    this.loading = false;
    this.calcResults.discountAmount = 0;
  },
  methods: {
    formatTotal(total: number) {
      return (total * 100).toFixed(3) + '%';
    },

    deleteDiscount() {
      const locationDiscount = {
        id: this.deleteDiscountId,
        name: '',
        value: 0,
        discountType: '',
        locationId: '',
      };
      this.handleDelete(locationDiscount);
    },

    async handleDelete(locationDiscount: any) {
      this.loading = true;
      await this.createDiscountService.removeDiscount(
        locationDiscount.id,
        this.selectedLocationId,
      );
      this.locationDiscount = this.locationDiscount.filter(
        (t: any) => t.id !== locationDiscount.id,
      );
      this.hideModalConfirm();
      this.loading = false;
    },

    async showModalConfirm(discountId: string, discountName: string) {
      const discountPromise =
        await this.createDiscountService.getLocationDiscountByID(
          discountId,
          this.selectedLocationId,
        );
      const result = JSON.parse(JSON.stringify(discountPromise.discount));
      result.discountType = result.discountType.data[0];
      result.isDeleted = result.isDeleted.data[0];
      this.selectedDiscountForDelete = result;
      this.deleteDiscountId = discountId;
      this.deleteDiscountName = discountName;
      // this.$bvModal.show('modal-confirm')
      this.showConfirmDelete = true;
    },

    showModalUpdate(discount: any) {
      this.showUpdateModal = true;
      this.selectedLocationDiscount = discount;
      this.discountName = discount.name;
      // this.$bvModal.show('modal-update')
      if (this.selectedLocationDiscount.discountType.data[0] === 0) {
        this.selectedDiscount = DiscountType.Percentage;
        this.percentageValue = this.selectedLocationDiscount.value;
      } else {
        this.selectedDiscount = DiscountType.Monetary;
        this.monetaryValue = this.selectedLocationDiscount.value;
      }
    },

    showPopuMe() {
      this.displayNotification('sucess', 'Pin has been updated.');
    },
    /*async updateDiscount(id: any) {
    this.loading = true;
    const updatedLocationDiscount = {
      id: id,
      name: this.discountName,
      value:
        this.selectedDiscount === 0 ? this.percentageValue : this.monetaryValue,
      discountType: this.selectedDiscount,
    };
    const res = await this.createDiscountService.updateLocationDiscount(
      updatedLocationDiscount,
      this.locationId
    );
    if (res) {
      this.hideModalUpdate();
      this.getAllDiscountLocations();
      this.loading = false;
    }
  }*/

    hideModalUpdate() {
      // this.$bvModal.hide('modal-update')
      this.discountName = '';
      this.percentageValue = '';
      this.monetaryValue = '';
      this.selectedDiscount = 0;
    },

    hideModalConfirm() {
      this.showConfirmDelete = false;
    },

    async getAllDiscountLocations() {
      this.loading = true;
      if (this.selectedLocationId) {
        const locationId = this.selectedLocationId;
        const response = await this.createDiscountService.getLocationDiscount(
          locationId,
        );
        this.locationDiscount = response;
      } else {
        const response = await this.createDiscountService.getLocationDiscount();
        this.locationDiscount = response;
      }
      this.loading = false;
    },

    async removeCurrentLocation() {
      this.showConfirmDelete = false;
      const preservedLocations = this.selectedDiscountForDelete.locationDiscount
        .map((locDis) => {
          if (this.selectedLocationId != locDis.location.id) {
            return locDis.location.id;
          }
        })
        .filter((locationId) => locationId !== undefined);

      const response = await this.createDiscountService.updateLocationDiscount(
        this.selectedDiscountForDelete,
        this.locationId,
        preservedLocations,
        this.locs,
      );

      const discountId = response.data.discount.id;
      if (discountId !== undefined || discountId !== null) {
        const discount = { ...this.discount, id: discountId };
        await this.createDiscountService.saveDiscountItems(
          discount,
          this.selectedLocationId,
        );
      }

      this.locationDiscount = this.locationDiscount.filter(
        (t: any) => t.id !== discountId,
      );
    },

    addDiscount(discount: Discount) {
      this.loading = true;
      this.popoverShow = false;
      if (discount) {
        this.getAllDiscountLocations();
        this.loading = false;
      }
    },

    closeDiscountPopover() {
      this.popoverShow = false;
      this.loading = false;
    },

    displayNotification(type: 'sucess' | 'error', msg: string, title = '') {
      this.activateNotification = true;
      this.typeNotification = type;
      this.titleNotification = title;
      this.messageNotification = msg;
    },
  },
});
